import { font, breakpoint, color } from '../styles/values';
import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const Navigation = styled.nav`
    display: flex;
    align-items: center;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            font-family: ${font.header};
            font-size: 24px;
            margin-left: 48px;
        }
    }

    @media (max-width: ${breakpoint.md}px) {
        display: none;
    }
`;

const MenuOpen = styled.button`
    display: none;
    height: 100%;
    width: auto;
    padding: 0;
    border: 0;
    outline: 0;
    background-color: transparent;

    @media (max-width: ${breakpoint.md}px) {
        display: block;
    }
`;

const MenuClose = styled.button`
    position: fixed;
    top: 20px;
    right: 10px;
    padding: 0;
    border: 0;
    outline: 0;
    z-index: 200;
    background-color: transparent;
`;

const SideMenuOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    z-index: -50;

    &.active {
        opacity: 0.75;
        z-index: 50;
    }
`;

const SideMenu = styled.nav`
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100vh;
    transition: width 0.3s linear;
    box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.2);
    background-color: ${color.background};
    z-index: 100;

    &.active {
        width: 300px;
    }

    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 300px;
        overflow: hidden;
        margin: 70px 0 0 0;
        padding: 0;

        li {
            list-style: none;
            font-family: ${font.header};
            font-size: 36px;
            text-align: center;
            padding: 20px 0;
        }
    }
`;

const menuContent = (
    <ul>
        <li>
            <Link to="/">Home</Link>
        </li>
        <li>
            <Link to="/wat-is-quidditch">Wat is Quidditch?</Link>
        </li>
        <li>
            <Link to="/workshops">Workshops</Link>
        </li>
        <li>
            <Link to="/contact">Contact</Link>
        </li>
    </ul>
);

export default () => {
    const [menuOpen, setMenuOpen] = useState(false);

    let closeBtn = null;

    if (menuOpen) {
        closeBtn = (
            <MenuClose onClick={() => setMenuOpen(!menuOpen)}>
                <img src="/close.svg" alt="Icon to clode the side menu." />
            </MenuClose>
        );
    }

    return (
        <>
            <Navigation aria-hidden={menuOpen}>{menuContent}</Navigation>
            <MenuOpen onClick={() => setMenuOpen(!menuOpen)}>
                <img src="/hamburger.svg" alt="Icon to open the side menu." />
            </MenuOpen>
            <SideMenu aria-hidden={!menuOpen} className={menuOpen ? 'active' : ''}>
                {menuContent}
            </SideMenu>
            <SideMenuOverlay className={menuOpen ? 'active' : ''}></SideMenuOverlay>
            {closeBtn}
        </>
    );
};
