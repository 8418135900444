import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    max-width: 1420px;
    display: block;
    margin: auto;
    padding: 0 10px;
`;

export default ({ children }) => {
    return <Container>{children}</Container>;
};
