import Layout from '../components/layouts/layout';
import Header from '../components/header';
import React from 'react';
import { graphql } from 'gatsby';
const PostTemplate = ({ data }) => {
    const post = data.contentfulPost;

    const img = post.featuredImage ? (
        <img
            src={post.featuredImage.fluid.src}
            srcSet={post.featuredImage.fluid.srcset}
            alt={post.featuredImage.description}
        />
    ) : null;

    return (
        <Layout>
            <Header />
            <h1>{post.title}</h1>
            {img}
            <div
                dangerouslySetInnerHTML={{
                    __html: post.childContentfulPostBodyRichTextNode.childContentfulRichText.html,
                }}
            />
        </Layout>
    );
};

export default PostTemplate;

export const pageQuery = graphql`
    query PostBySlug($slug: String!) {
        contentfulPost(slug: { eq: $slug }) {
            title
            featuredImage {
                fluid {
                    srcSet
                    src
                }
                description
            }
            childContentfulPostExcerptRichTextNode {
                childContentfulRichText {
                    html
                }
            }
            childContentfulPostBodyRichTextNode {
                childContentfulRichText {
                    html
                }
            }
        }
    }
`;
