export const color = {
    background: '#fff9e7',
    accent: '#ffc400',
    accentDark: '#c49205',
    main: '#000000',
    footerBackground: '#333333',
    textLighter: '#595959',
};

export const font = {
    header: '"Staatliches", cursive',
    body: "'Lato', sans-serif",
};

export const breakpoint = {
    lg: 1400,
    md: 900,
    sm: 450,
};
