import Layout from '../components/layouts/layout';
import Header from '../components/header';
import React from 'react';
import { graphql } from 'gatsby';

const PageTemplate = ({ data }) => {
    const page = data.contentfulPage;

    return (
        <Layout>
            <Header></Header>
            <h1>{page.title}</h1>
            <div
                dangerouslySetInnerHTML={{
                    __html: page.childContentfulPageBodyRichTextNode.childContentfulRichText.html,
                }}
            />
        </Layout>
    );
};

export default PageTemplate;

export const pageQuery = graphql`
    query PageBySlug($slug: String!) {
        contentfulPage(slug: { eq: $slug }) {
            title
            childContentfulPageBodyRichTextNode {
                childContentfulRichText {
                    html
                }
            }
        }
    }
`;
