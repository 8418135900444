import Menu from './menu';
import { color, font, breakpoint } from '../styles/values';
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 70px;
`;
const Logo = styled(Link)`
    font-family: ${font.header};
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:after {
        content: none;
    }

    img {
        width: auto;
        height: 60px;
        margin-right: 15px;

        @media (max-width: ${breakpoint.md}px) {
            height: 50px;
            margin-right: 5px;
        }
    }
`;

const LogoText = styled.span`
    font-size: 48px;
    text-shadow: -2px 0px 0px ${color.accentDark};

    @media (max-width: ${breakpoint.md}px) {
        font-size: 36px;
        text-shadow: -1.5px 0px 0px ${color.accentDark};
    }

    @media (max-width: ${breakpoint.sm}px) {
        font-size: 24px;
        text-shadow: -1px 0px 0px ${color.accentDark};
    }
`;

export default () => {
    return (
        <Wrapper>
            <Logo to="/">
                <img
                    src="/logo-empty.png"
                    alt="Het Logo van de Groningen Griffins. Zwart hoofd van een griffoen op een geel schild."
                />
                <LogoText>Groningen Griffins</LogoText>
            </Logo>
            <Menu />
        </Wrapper>
    );
};
