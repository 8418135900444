import Header from '../components/header';
import Layout from '../components/layouts/layout';
import React from 'react';
import { graphql, Link } from 'gatsby';

const IndexPage = ({ data }) => {
    const postsData = data.allContentfulPost.edges;

    const posts = postsData.map((post) => (
        <li key={post.node.id}>
            <Link to={'blog/' + post.node.slug}>{post.node.title}</Link>
        </li>
    ));

    return (
        <Layout>
            <Header></Header>
            <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h1>
            <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>
            <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
            <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
            <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h5>
            <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h6>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, suscipit quia, ipsa
                quis laboriosam culpa sequi dicta ipsam sunt aliquam neque assumenda aliquid
                blanditiis quaerat velit voluptas. Quaerat, architecto impedit!
            </p>
            <ul>{posts}</ul>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query PostQuery($locale: String!) {
        allContentfulPost(filter: { node_locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    title
                    slug
                    featuredImage {
                        fluid {
                            src
                        }
                    }
                    childContentfulPostExcerptRichTextNode {
                        childContentfulRichText {
                            html
                        }
                    }
                    childContentfulPostBodyRichTextNode {
                        childContentfulRichText {
                            html
                        }
                    }
                }
            }
        }
    }
`;
